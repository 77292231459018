import React from 'react'

const Footer = () => {
  return (
    <div name='footer' className='w-full mx-auto px-8 bg-[#1F1F21] text-gray-300'>

      <div className='text-center pt-32 pb-20'>
        <p className='pt-8'>Developed using React and Tailwind CSS by Christina Mullen © 2024</p>
      </div>
    </div>
  )
}

export default Footer