import React, { useEffect, useState } from 'react';

const TakeawaysWW = () => {

    return (
        <div name='takeaways' className='flex flex-col justify-center bg-[#9995C8]'>

            <div className='w-full mx-auto px-8 sm:px-32 pt-8 flex flex-col items-center justify-center bg-[#9995C8]'>
                <h1 className='sm:text-4xl font-bold inline border-4 px-4 py-2 my-8 tracking-wider bg-[#1F1F21] border-pink-600 text-[#F5F5FA]'>Takeaways</h1>
                <p className='max-w-[640px] pb-16'>
                    We had a lot of fun with this project. We found that there was a lot of public curiosity about homestays. We found that our participants really enjoyed poking around in our app and not following instructions with our Maze testing. It was challenging to direct their interest to complete the task before exploring the prototype. If I had to do it all over, we would have allowed more time for user testing, especially allowing more time for iteration within the user surveys and testing.
                </p>
            </div>
        </div>
    )
}

export default TakeawaysWW